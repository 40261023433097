import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import {Container, Grid, SubTitle} from '../styles/global';
import styled from "styled-components"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const Thumbs = styled(Img) `
  margin: 20px 0;
`

// markup
const NousPage = () => {


   const data = useStaticQuery(graphql`
  query {
    image1: file(relativePath: { eq: "salon_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }    }
    image2: file(relativePath: { eq: "salon_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }    }
    image3: file(relativePath: { eq: "salon_3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }  
    }
    image4: file(relativePath: { eq: "salon_4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }  
    }
    image5: file(relativePath: { eq: "salon_5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }  
    }
    image6: file(relativePath: { eq: "salon_6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }  
    }
  }
`)
/*
  const data = useStaticQuery(graphql`
     {
        file(relativePath: { eq: "salon_1.jpg" }) {
        childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        
      }
    }
  `)
*/
  return (
    <Layout>
    <main style={pageStyles}>
      <Container>
        <title>Nous – Tête à Clak</title>
        <Grid>
            <div className={'grid-12 col gauche'}>
              <Thumbs fluid={data.image1.childImageSharp.fluid} alt="Tableau présent dans le salon" />
              <SubTitle>L’équipe</SubTitle>
              <h3>Igor – Maître des lieux</h3>
              <p>Igor respire l’air du temps et cultive la longueur d’avance. Trendsetter, insatiable créatif il s’inspire de la personnalité de chaque client(e) et crée en totale harmonie avec le caractère de chaque modèle. En recherche permanente d’élégance contemporaine, l’essentiel, pour lui, reste l’adéquation entre la création capillaire et le style de chacun.e. Il a su fédérer une équipe de passionnés qu’il nourrit au quotidien de sa propre passion, de son amour du métier, de son exigence sans faille et de ses idées.</p>
              <h3>Axel</h3>
              <p>Plus d’une décennie de créativité. Sa passion, proposer du changement, modifier, permettre à sa cliente de se réinventer. Son inspiration il la trouve dans la rue des grandes mégalopoles, sur les catwalks aussi. Il se laisse enrichir par la richesse des différentes cultures et l’originalité d’inconnus croisés au hasard d’un voyage. S’il devait avoir une préférence dans sa pratique, ça serait la coupe/couleur ou le changement de look, pour la créativité sans fin bien sûr.</p>
              <h3>Junior</h3>
              <p>Laissez vous séduire par l’univers glam-rock de Junior. Ce spécialiste du blond sous toutes ses formes saura éveiller l’envie et concrétiser vos attentes (les brunes et les rousses sont évidemment plus que bienvenues également entre ses mains).</p>
              <p>Délaissant son salon d’Annecy *Un brun de folie*, et ralentissant son rythme de Formateur balayage à l’internationale il s’installe ici à résidence (sauf les jeudis histoire de retourner coiffer ses clientes *en manque* une fois par semaine).<br/>De la transformation totale, au simple balayage, nous nous réjouissons de vous faire découvrir sa touche. Pour vous messieurs, il adore les coupes hommes et barbes.</p>
              <h3>Camille</h3>
              <p>Camille c’est le conseil et la passion personnalisés. Eclectique, elle apprécie chaque personnalité, genre et type de cheveu pour vous accompagner sur le chemin de ce qui vous correspondra de manière optimale. Son plaisir ? Partir du diagnostic, développer l’idée avec vous et vous voir apprécier un résultat auquel vous n’auriez pas forcément songé en passant nos portes. En veille stylistique permanente sur les réseaux, magazines et autres, elle saura exploiter les nouvelles tendances ou appliquer un style intemporel. Travaille du mercredi au samedi.</p>
              <Thumbs fluid={data.image3.childImageSharp.fluid} alt="Vitrine du salon" />
              <SubTitle>Nos partenaires privilégiés</SubTitle> 
              <p><strong>GREAT LENGTH</strong>, le leader mondial des extensions. Importés d’Inde exclusivement, les cheveux offrent une parfaite compatibilité moléculaire avec le cheveux occidental, il est également possible, comme sur votre chevelure, de colorer ou couper les extensions pour faire évoluer la coiffure.
              <br/>Ce qui nous parait également essentiel chez Great Lengths - au même titre que leur éthique capillaire - c’est leur éthique entrepreneuriale, qui favorise la main d’oeuvre locale, assure sa formation, respecte la culture de ses collaborateurs. La marque a financé en Inde hôpitaux, dispensaires et écoles.</p>
              <p><strong>KEVIN MURPHY</strong>  Disciple du célèbre Vidal Sassoon et les looks de Kevin Murphy ont toujours fait la Une des plus grands magazines. Mécontentement de l'offre disponible, il décida de lancer sa propre ligne. Sa philosophie : ne pas alourdir les cheveux, les styliser et les soigner avec des composants haut de gamme et naturels (sélectionnés avec soin et enrichis de vitamines et acides aminés).
              <br/>C’est la marque qui est capable de répondre à tout type de cheveux, du plus fin au plus crépu. Ses packaging minimalistes sont issus de déchets de plastique des océans 100% recyclables, ou comment faire du bien à ses cheveux et à l’environnement.</p>
              <p><strong>ORIBE</strong> Légende dans le monde de la mode, Oribe Canales a fortement marqué l'ère des supermodels dans les années 90 avec son style signature "Big Hair". Sa longue expérience acquise lors d'innombrables shootings pour des magazines et des campagnes, des défilés de mode et dans son propre salon lui a permis de développer une collection de soins capillaires et de styling, très appréciée par les professionnels et véritable luxe au quotidien. Oribe allie l'artisanat, la technologie innovante des produits et des décennies d'expérience en matière de coiffure. Sans gluten et végétariens, leurs produits sont proudly approved by Peta.</p>
            </div>
            <div className={'grid-12 col droite'}>
              <SubTitle>La coiffure est une composante essentielle de la personnalité de chacun</SubTitle>
              <p>Pour les femmes, bien-sûr, il permet de révéler son identité, de booster son capital séduction, d’accroître sa confiance en soi...</p>
              <p>Il est également prépondérant dans le bien-être masculin. Disposant de moins d’accessoires que la gent féminine, une coupe (ou couleur) adéquate permet à chacun de se singulariser, d’asseoir son sens du style et d’affirmer son caractère.</p>
              <p>Enfin quel enfant n’a pas rêvé d’avoir une coiffure stylée avec laquelle il puisse se sentir lui-même (adieu coupe au bol réalisée avec le saladier familial !).</p>
              <p>C’est pour vous accompagner dans cette découverte personnelle et vous aider à entretenir vos choix de coiffure au quotidien que nous mettons créativité et expérience à votre service dans notre salon de coiffure à Vevey. Notre team passionnée vous accompagne et vous explique comment prendre soin de votre look au quotidien.</p>
              <p>Afin de compléter la mise en valeur de vos cheveux nous vous proposons aussi relooking, et conseils beauté...</p>
              <p>Notre équipe est composée de talents ou futurs talents (pour les apprentis) triés sur le volet. Un même amour de la coiffure et une grande expérience technique unit ces personnes venant d’horizons différents. Leurs inspirations diverses permettent d’assurer une palette complète dans laquelle chaque client.e trouvera son bonheur.</p>
              <p>Salon de coiffure mais également lieu de vie, laissez- vous guider par vos envies, nos idées et faites de votre rendez-vous un moment de détente et de plaisir.</p>
              <Thumbs fluid={data.image2.childImageSharp.fluid} alt="Produits Oway" />
              <Thumbs fluid={data.image4.childImageSharp.fluid} alt="Décoration du salon" />
              <Thumbs fluid={data.image5.childImageSharp.fluid} alt="Coupe de cheveux" />
              <Thumbs fluid={data.image6.childImageSharp.fluid} alt="Produits Shu Uemura Art of Hair" />
            </div>
        </Grid>
        </Container>
    </main>
    </Layout>
  )
}

export default NousPage
